<script setup lang="ts">
const props = defineProps(["name"]);

// We only support 3 icons for now
// These match the list in DatoCMS
// Find icon names at: https://icones.js.org/collection/ph
const icons = {
  cart: "i-ph-shopping-cart",
  marker: "i-ph-map-pin-line",
  chat: "i-ph-chats-circle",
} as Record<string, string>;

const iconName = computed(() => icons[props.name]);
</script>

<template>
  <div class="mb-3 flex size-12 items-center justify-center rounded-full bg-white text-fruit">
    <UIcon v-if="iconName" :name="icons[props.name]" class="size-6" />
  </div>
  
</template>
