<script lang="ts" setup>
import { DATO_DRAFT_COOKIE_NAME } from "@/server/utils/datoCmsDraftCookie";

// here we only check if the cookie exists
// actual content of the cookie is checked on the server
const isDraft = useCookie(DATO_DRAFT_COOKIE_NAME);
</script>

<template>
  <div v-if="isDraft" class="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-center text-xs text-white">
    DatoCMS Draft mode is enabled.
    <a href="/api/datocms/disable-draft" class="underline">Disable</a>
  </div>
</template>

<style scoped>

</style>
