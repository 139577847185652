<script lang="ts" setup>
import type { LayoutRecord } from "~/graphql/datocms/generated";

const props = defineProps<{ data: LayoutRecord }>();

const localePath = useLocalePath();
const links = computed(() => createFooterColumnLinks(props.data.footerColumns, localePath));
</script>

<template>
  <div v-if="data" class="grid xl:grid-cols-3 xl:gap-12">
    <div class="col-span-2">
      <FooterColumns :links="links" />
    </div>
    <div class="col-span-1">
      <FooterSubscription :data="data.newsletterSubscriptionForm" />
    </div>
  </div>
</template>

<style scoped>
</style>
