import type { H3Event } from "h3";
import { decrypt, encrypt } from "./basicEncrypt";

export const DATO_DRAFT_COOKIE_NAME = "__dato_preview";
export const DATO_DRAFT_SECRET = "6546504651310616";

const EXPIRATION_SECONDS = 60 * 60 * 24;
const COOKIE_VALUE = "1";

export async function determineDatoCmsDraftEnabled(event: H3Event): Promise<boolean> {
  const encryptedValue = getCookie(event, DATO_DRAFT_COOKIE_NAME);
  if (!encryptedValue) {
    return false;
  }

  try {
    const value = await decrypt(encryptedValue, DATO_DRAFT_SECRET);
    return value === COOKIE_VALUE;
  }
  catch (error) {
    return false;
  }
}

export async function createCookieValue(): Promise<string> {
  return encrypt(COOKIE_VALUE, DATO_DRAFT_SECRET, EXPIRATION_SECONDS);
}
