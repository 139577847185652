import * as jose from "jose";

const SEPARATOR = ":";
const ENCODER = new TextEncoder();

export const DEFAULT_SECRET = "6546504651310616";

export async function encrypt(value: string, secret: string, expirationInSecords: number): Promise<string> {
  const data = `${Date.now() + (expirationInSecords * 1000)}${SEPARATOR}${value}`;
  const jws = await new jose.CompactSign(
    ENCODER.encode(data),
  )
    .setProtectedHeader({ alg: "HS256" })
    .sign(ENCODER.encode(secret));

  // console.log({ jws: jws });
  return jws;
}

export async function decrypt(value: string, secret: string): Promise<string | null> {
  if (!value) {
    return null;
  }

  const key = ENCODER.encode(secret);
  const { payload } = await jose.compactVerify(
    value,
    key,
  );
  const val = new TextDecoder().decode(payload);
  // console.log(val);

  const [timestamp, ...data] = val.split(SEPARATOR);
  if (Number(timestamp) > Date.now()) {
    return data.join(SEPARATOR);
  }
  else {
    return null;
  }
}
