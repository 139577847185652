<script lang="ts" setup>
const localePath = useLocalePath();
const { locale } = useI18n();
const { data } = await useFetch("/api/layout", { query: { locale: locale } });

const isOpenMobileMenu = ref(false);
const isOpenWebshopModal = ref(false);

function onCloseWebshopModal() {
  isOpenWebshopModal.value = false;
}
</script>

<template>
  <header v-if="data">
    <div class="container mx-auto">
      <div class="my-md flex items-center justify-between md:my-lg">
        <NuxtLinkLocale to="/">
          <NuxtImg src="/logo/spuntini-logo-text.svg" alt="Spuntini Logo" title="Spuntini" class="w-32 lg:w-48" />
        </NuxtLinkLocale>

        <div class="hidden items-center lg:flex">
          <nav class="mx-3xl flex gap-lg">
            <NuxtLinkLocale
              v-for="item in data.topNavigation"
              :key="item.id"
              :to="`/${item.slug}`"
              :class="{ 'text-primary !border-primary': $route.path?.toString().includes(item.slug) }"
              class="border-b-2 border-transparent text-md leading-6 transition hover:border-th-neutral-500"
              active-class="text-primary !border-primary"
            >
              {{ item.title }}
            </NuxtLinkLocale>
          </nav>

          <div class="flex gap-md">
            <UButton variant="outline" color="primary" size="sm" @click="isOpenWebshopModal = true">
              {{ data.webshopButtonText }}
            </UButton>

            <HeaderLanguageSwitcher />
          </div>
        </div>

        <div class="flex items-center gap-lg lg:hidden">
          <div class="relative">
            <VectorWithStroke class="vector--main-nav-button absolute -left-0.5 -top-0.5 h-[0.9rem] w-[1rem] rotate-[55deg]" />
            <UButton variant="solid" color="primary" size="xs" :to="localePath(`/${data.headerButtonLink.slug}`)">
              {{ data.headerButtonText }}
            </UButton>
          </div>

          <UIcon name="i-ph-list" class="size-10 cursor-pointer" @click="isOpenMobileMenu = true" />
        </div>
      </div>

      <div class="hidden rounded-full bg-white lg:block">
        <div class="flex items-center justify-between">
          <nav class="mx-xl flex flex-wrap gap-xl">
            <NuxtLinkLocale
              v-for="item in data.mainNavigation"
              :key="item.id"
              :to="`/${item.slug}`"
              :class="{ 'text-primary !border-primary': $route.path?.toString().includes(item.slug) }"
              class="border-b-2 border-transparent py-[22px] text-md font-semibold transition hover:border-th-neutral-500"
              active-class="text-primary !border-primary"
            >
              {{ item.title }}
            </NuxtLinkLocale>
          </nav>

          <div class="relative">
            <VectorWithStroke class="vector--main-nav-button absolute left-2 top-2 h-[0.9rem] w-[1rem] rotate-[55deg]" />
            <UButton variant="solid" color="primary" size="md" :to="localePath(`/${data.headerButtonLink.slug}`)" class="m-xs">
              {{ data.headerButtonText }}
            </UButton>
          </div>
        </div>
      </div>
    </div>

    <USlideover v-model="isOpenMobileMenu" prevent-close>
      <div class="flex flex-1 overflow-y-auto bg-[#F8F7F4]">
        <div class="size-full px-md">
          <div class="my-md flex items-center justify-between">
            <NuxtLinkLocale to="/" class="focus-visible:outline-none">
              <NuxtImg src="/logo/spuntini-logo-text.svg" alt="Spuntini Logo" title="Spuntini" class="w-32 lg:w-48" />
            </NuxtLinkLocale>

            <UIcon name="i-ph-x" class="size-10 cursor-pointer" @click="isOpenMobileMenu = false" />
          </div>

          <nav class="my-xl flex flex-col gap-lg">
            <NuxtLinkLocale
              v-for="item in data.mainNavigation"
              :key="item.id"
              :to="`/${item.slug}`"
              :class="{ 'text-primary !border-primary': $route.path?.toString().includes(item.slug) }"
              class="border-b-2 border-transparent text-md leading-6 hover:border-th-neutral-500"
              active-class="text-primary !border-primary"
              @click="isOpenMobileMenu = false"
            >
              {{ item.title }}
            </NuxtLinkLocale>

            <NuxtLinkLocale
              v-for="item in data.topNavigation"
              :key="item.id"
              :to="`/${item.slug}`"
              :class="{ 'text-primary !border-primary': $route.path?.toString().includes(item.slug) }"
              class="border-b-2 border-transparent text-md leading-6 hover:border-th-neutral-500"
              active-class="text-primary !border-primary"
              @click="isOpenMobileMenu = false"
            >
              {{ item.title }}
            </NuxtLinkLocale>
          </nav>

          <div class="my-xl flex flex-col gap-md">
            <UButton variant="solid" color="primary" size="sm" :to="localePath(`/${data.headerButtonLink.slug}`)" @click="isOpenMobileMenu = false">
              {{ data.headerButtonText }}
            </UButton>

            <UButton variant="outline" color="primary" size="sm" @click="isOpenWebshopModal = true">
              {{ data.webshopButtonText }}
            </UButton>
          </div>

          <div class="my-xl">
            <HeaderLanguageSwitcher @changed="isOpenMobileMenu = false" />
          </div>
        </div>
      </div>
    </USlideover>

    <LazyHeaderWebshopModal :is-open="isOpenWebshopModal" @close="onCloseWebshopModal" />
  </header>
</template>

<style>
.vector--main-nav-button {
  --esc-vector-fill-color: #C81414;
  --esc-vector-opacity: 1;
  --esc-vector-mix-blend-mode: normal;
  --esc-vector-stroke-color: #FFF;
  --esc-vector-stroke-width: 8;
}
</style>
