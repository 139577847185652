<script lang="ts" setup>
import type { LocaleObject } from "@nuxtjs/i18n/dist/runtime/composables";

const emit = defineEmits<{
  (e: "changed"): void
}>();
const { locale: currentLocale, locales } = useI18n();
const router = useRouter();
const localeList = unref(locales) as LocaleObject[];

const items = [localeList.map((locale: LocaleObject) => {
  return {
    label: locale.code!.substring(0, 2).toUpperCase(),
    click: () => {
      localeSelected(locale.code!);
    },
  };
})];

function localeSelected(localeCode: string) {
  // POSSIBLE BUG
  // The setLocale function from useI18n doesn't currently know
  // how to change paths for a page that is not available in the other locale

  // setlocale will change the path and also update the cookie for next visit to the root
  // setLocale(localeCode);

  // Force to homepage
  router.push(`/${localeCode}`);

  // Close mobile menu (if open)
  emit("changed");
}
</script>

<template>
  <div>
    <UDropdown :items="items" :ui="{ background: 'bg-[#F3F1EC]', width: 'w-16', item: { base: 'justify-center text-primary font-medium' } }" :popper="{ placement: 'bottom-start' }" class="hidden lg:block">
      <UButton variant="solid" color="primary" size="xs" trailing-icon="i-ph-caret-down" :ui="{ strategy: 'override', variant: { solid: `bg-[#EBE9E9] text-primary` } }">
        {{ currentLocale.substring(0, 2).toUpperCase() }}
      </UButton>
    </UDropdown>

    <div class="flex gap-md lg:hidden">
      <NuxtLink
        v-for="option in (locales as LocaleObject[])"
        :key="option.code"
        :to="`/${option.code}`"
        :class="{
          'bg-[#F3F1EC]': option.code !== currentLocale,
          'bg-[#EBE9E9]': option.code === currentLocale,
        }"
        class="text-primary flex size-10 items-center justify-center rounded-full text-sm font-medium"
        @click.prevent.stop="localeSelected(option.code)"
      >
        {{ option.code!.substring(0, 2).toUpperCase() }}
      </NuxtLink>
    </div>
  </div>
</template>

<style>

</style>
