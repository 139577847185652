<script lang="ts" setup>
import type { LayoutRecord } from "~/graphql/datocms/generated";

const { locale } = useI18n();
const { data } = await useFetch<LayoutRecord>("/api/layout", { query: { locale: locale } });
</script>

<template>
  <footer v-if="data" class="mt-6 bg-[#f3f1ec]">
    <div v-if="data?.usps">
      <div class="container mx-auto py-10">
        <FooterUsps :data="data.usps" />
      </div>
    </div>

    <div class="rounded-t-lg bg-th-neutral-500 text-white">
      <div class="container mx-auto py-10">
        <FooterMain :data="data" />
      </div>
    </div>

    <div class="bg-[#503E3E] text-sm text-white">
      <div class="container mx-auto py-3">
        <FooterClosing :data="data" />
      </div>
    </div>

    
  </footer>
</template>

<style scoped>
</style>
